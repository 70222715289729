// Custom SCSS
// General

body {
  background: linear-gradient($body-bg-header 10%, $body-bg 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  text-shadow: 0 0 0.01em $shadow-color;
}

a {
  cursor: pointer;
}

blockquote {
  border-color: $gray;
  border-width: 0 0 0 0.2em;
  border-style: solid;
}

h1 {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

h1 a,
h1 a:hover {
  color: $brand-primary;
}


h1,
h2,
h3,
h4,
h5,
h6 {
  color: $brand-danger;
  text-decoration-color: $brand-danger;
  border-bottom-color: $brand-danger;
}

#background-div,
#background-svg {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
}

#title {
  margin-top: 0;
}

// Social media hover colors colors
$icon-shadow: 0.1em;
$icons-list: github facebook twitter linkedin envelope;
$icons-colors: $github-color $facebook-color $twitter-color $linkedin-color $envelope-color;
@for $i from 1 through length($icons-list) {
  .fa-#{nth($icons-list, $i)}:hover {
    text-shadow: 0 0 $icon-shadow #{nth($icons-colors, $i)};
  }
}

.bgColor1 {
  stop-color: $bg-color1;
}

.bgColor2 {
  stop-color: $bg-color2;
}

.page-footer {
  margin-top: 8em;

  center div div a i {
    color: $gray !important;
  }
}

.navbar-toggle:hover {
  background-color: transparent !important;
}

.navbar-icon:hover {
  text-shadow: 0 0 $icon-shadow lighten($brand-primary, 20%);
  color: $brand-primary !important;
}

.navbar-icon {
  color: $brand-primary !important;
}

.navbar {
  background-color: $navbar-bg;
  transition: background-color 0.25s ease-in-out;
  -webkit-transition: background-color 0.25s ease-in-out;
}

nav:hover {
  background-color: $navbar-default-bg;
}

.post-title {
  color: $brand-primary;
}

.blog-post {
  margin-bottom: 5em;
}

$avatar-len: 20em;
$avatar-width: 50em;
$avatar-size: 50em;
.avatar-image {
  height: auto;
  width: auto;
  max-height: $avatar-len;
  max-width: $avatar-width;
  border-radius: $avatar-size;
  border-style: solid;
  border-width: medium;
  border-color: $gray-light;
}

.avatar-description {
  color: $gray-light;
}

.hide {
  display: none;
}

.h1-overlay {
  background: $h1-bg;
  position: absolute;
  width: 100%;
  z-index: -1;
  padding: 1em;
}

.right-links {
  a i {
    font-size: 1.5em;
    color: $gray !important;
  }
}
